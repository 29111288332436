import React, { useEffect, useState ,useRef } from 'react'
import { useFormik } from "formik";
import {
	Col,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	Input,
	Label,
	ModalFooter ,
	Button,
	Form,
	FormGroup,
	FormFeedback,
	Spinner,
} from "reactstrap";
import hasPermission from "../../common/HasPermission";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";
import { apiUrl } from "../../config";
import Erc20ABI from "../../ABI/Erc20.json"
import "../../assets/scss/launchpad.scss";
import PublicSaleABI from "../../ABI/publicsaleFactory.json"
import newProjctSale from "../../ABI/newProjectSale.json"
import FacoryContract from "../../ABI/facoryContract.json"
import {
	clearResponse,
	updateProject,
} from "../../store/admin/actions";
import { FaInfoCircle } from 'react-icons/fa';
import CSVReader from 'react-csv-reader';
import Web3 from 'web3';

const ContractInteraction = ({
	initializeWeb3,
	isAdditionalSetting,
	projectSetting,
	couldHaveAddUpdatePermission,
	settingData,
	formik,
	formikReclaim,
	switchOrAddChain,
}) => {

	const dispatch = useDispatch();
    const [newContractAddress, setNewContractAddress] = useState("");
    const [withdrawAddress, setWithdrawAddress] = useState("");
	const [airdropAddress, setAirdropAddress] = useState([]);
	const [airdropRoot, setAirdropProofRoot] = useState([]);
	const [airdropwalletscount, setAirdropwallets] = useState([]);
	const [inputcount, setInputs] = useState([]);
	const [isDeployer, setIsDeployer] = useState(false)
	const [isLoading, setIsLoading] = useState(false);
	const [getTotoalUsdtToken, setTotalUsdtToken] = useState(0);

	const [addresses, setAddresses] = useState([]);
    const [deposits, setDeposits] = useState([]);
    // const [allocations, setAllocations] = useState([]);
	const [isBalance, setIsBalance] = useState(true); // Flag to determine if balance or allocation

	const { response, auth } = useSelector((state) => ({
		response: state.admin.response,
		auth: state.auth,
	}));

	const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);

	const projectSale = newProjctSale;

	useEffect(() => {
		if (settingData.id !== undefined) {
			const fetchAirdropaddress = async () => {
				if(settingData.id !== undefined){
					try {
						const response = await axios.get(`${apiUrl}/proofs/airdrop/${settingData.id}`);
						const newValue = response.data.walletAddress?.replaceAll(",", "\n")?.replaceAll("~", ":") //.replaceAll(" ","");
						setAirdropAddress(newValue);
						setAirdropProofRoot(response.data.proof);
						const lineCount = (newValue.match(/\n/g) || []).length + 1;
						setInputs(lineCount)
						setAirdropwallets(lineCount)
					} catch (error) {}
				}
			};
			fetchAirdropaddress();
			setAirdropAddress("")
			setAirdropProofRoot("")
			setInputs(0)
			setAirdropwallets(0)
			setNewContractAddress("")
			setWithdrawAddress("")
			// setAllocations([])
			setDeposits([])
			setAddresses([])
		}
	}, [settingData]);

	useEffect(() => {
		const fetchDeployer = async () => {
			const web3 = initializeWeb3();
			if (!web3) {
				Swal.fire('Error', 'Web3 initialization failed.', 'error');
				return;
			}
			const accounts =  window.ethereum.request({ method: 'eth_requestAccounts' });
			const deployer = await sessionStorage.getItem('metamaskAddress', accounts[0]); 
			if(deployer !== null){
			try{

				const facoryContract = FacoryContract;
				let factorycontractAddress = await settingData.network.factoryContractAddress
				let factory = new web3.eth.Contract(facoryContract, factorycontractAddress);
				const isFactoryAdmin = await factory.methods.adminList(deployer).call();

				// let contractAddress = await settingData.launchpadContractAddress
				// let contract = new web3.eth.Contract(projectSale, contractAddress);
				// const isAdmin = await contract.methods.adminList(deployer).call();

				if (deployer.toString().toLowerCase() === settingData?.deployer.toString().toLowerCase() || isFactoryAdmin) {
					setIsDeployer(true)
					return
				}
					setIsDeployer(false)
					Swal.fire({
					title: "Error!",
					text: "Wallet is unauthorized",
					icon: "error",
				})
			}catch(e){
				console.log(e)
			}
			}	
		}
		fetchDeployer()
	}, [settingData])

	useEffect(() => {
		const fetchResponse = async() => {
		if (response) {
			if (response.message === "Project updated successfully" ) {
				Swal.fire({
					title: "Success!",
					text: response.message,
					icon: "success",
				}).then(() => {
					dispatch(clearResponse());
				});
			} else if (response.code === "201" || response.proof) {}
			else if (response.message) {
				Swal.fire({
					title: "Error!",
					text: response.message,
					icon: "error",
				}).then(() => {
					dispatch(clearResponse());
				});
			}
		}
	}
	fetchResponse()
	}, [dispatch, response]);

	const withdrewUSDT= async() =>{
		const web3 = initializeWeb3();
		if (!web3) {
			Swal.fire('Error', 'Web3 initialization failed.', 'error');
			return;
		}

		try {
			await switchOrAddChain(settingData.network);
		} catch (error) {
			Swal.fire('Error', 'Network switch/addition failed: ' + error.message, 'error');
			return;
		}
			const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
			const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
		if (!fromAddress) {
			Swal.fire('Error', 'Please connect your wallet', 'error');
			return;
		}

		const publicSaleABI = PublicSaleABI;

		// // hardcoding to withdraw funds from old smart contract to new smart contract

		 const contractPublicSale = new web3.eth.Contract(publicSaleABI, settingData.launchpadContractAddress);
		//const contractPublicSale = new web3.eth.Contract(publicSaleABI, '0xbf873427f62f2a95fdcb96c577d8db000707cb04');

		try {


			// let allocationHash = await contractPublicSale.methods.allocationHash().call()
			// if(allocationHash === '0x0000000000000000000000000000000000000000000000000000000000000000'){
			// 	Swal.fire('Error','Please upload allocation hash first!','error')
			// 	return
			// }

			// let totalUsdtDeposited = await contractPublicSale.methods.totalUsdtDeposited().call()
			// let excessDeposit = await contractPublicSale.methods.excessDeposit().call()
			// let fee = await contractPublicSale.methods.fees().call()
			// let withdrawn = await contractPublicSale.methods.totalUsdtWithdrawn().call()
			
			// totalUsdtDeposited = parseFloat(totalUsdtDeposited , 10) / (10 ** settingData.network.currencies[0].decimal)
			// excessDeposit = parseFloat(excessDeposit , 10) / (10 ** settingData.network.currencies[0].decimal)
			// withdrawn = parseFloat(withdrawn , 10) / (10 ** settingData.network.currencies[0].decimal)

			// totalUsdtDeposited = parseFloat(totalUsdtDeposited , 10) / (10 ** decimal)
			// excessDeposit = parseFloat(excessDeposit , 10) / (10 ** decimal)
			// withdrawn = parseFloat(withdrawn , 10) / (10 ** decimal)

			// const withdrawable = totalUsdtDeposited - excessDeposit - (parseFloat(fee.depositFee, 10) + parseFloat(fee.withdrawFee , 10))
			// if(withdrawable - withdrawn > 0){

			if (withdrawAddress.toString()?.toLowerCase() === '0x0f6096f68d7af01ce1b3150f8278e0057a219668'){
				const withdrawUSDT = await contractPublicSale.methods
					.withdrawUSDT(withdrawAddress)
					.send({ from: fromAddress });
				Swal.fire('Success', 'Withdraw USDT successful!', 'success');
			}else{
				Swal.fire('Error', 'Please check the address provided!', 'error');
			}
				
			// }
			// else Swal.fire('Error', 'Already withdrawn / Nothing to withdraw', 'error')
			
		} catch (error) {
			console.error('Error in withdraw', error);
			Swal.fire('Transaction Error', 'Error during withdraw ' + error.message, 'error');
		}
	}

	const refundOption = async (e) => {
		e.preventDefault()

		const web3 = initializeWeb3();
		if (!web3) return console.error('Web3 initialization failed.');

		try {
			await switchOrAddChain(settingData.network);
		} catch (error) {
			return console.error('Network switch/addition failed:', error);
		}

		const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
		const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
		if (!fromAddress) return  Swal.fire({
			icon: 'error',
			title: 'Wallet not connected',
			text: 'Please connect your wallet',
		});

		const publicSaleABI = PublicSaleABI;
		const contractPublicSale = new web3.eth.Contract(publicSaleABI, settingData.launchpadContractAddress);
		if (formik.values.refundType == "Reclaim Address") {
			try {
				const addLiquidityReceipt = await contractPublicSale.methods
					.addbuyBackOrReclaimAddress(formik.values.refundAddress, 1)
					.send({ from: fromAddress });
					var formData = new FormData();
					formData.append("refundType", "Refund");
					await dispatch(updateProject({ id: settingData.id, data: formData }));
				
			} catch (error) {
				Swal.fire({
					title: "Error!",
					text: `Error during Reclaim address transaction: ${error}`,
					icon: "error",
				})
			}
		} else if (formik.values.refundType === "Buyback Address") {
			try {
				const addLiquidityReceipt = await contractPublicSale.methods
					.addbuyBackOrReclaimAddress(formik.values.refundAddress, 0)
					.send({ from: fromAddress });
					var formData = new FormData();
					formData.append("refundType", "Buyback");
					await dispatch(updateProject({ id: settingData.id, data: formData }));
			} catch (error) {
				Swal.fire({
					title: "Error!",
					text: `Error during Buyback address transaction: ${error}`,
					icon: "error",
				})
			}
		}
	}

	const reclaimOption = async () => {
		const web3 = initializeWeb3();
		if (!web3) return console.error('Web3 initialization failed.');

		try {
			await switchOrAddChain(settingData.network);
		} catch (error) {
			return console.error('Network switch/addition failed:', error);
		}

		const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
		const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
		if (!fromAddress) return Swal.fire({
			icon: 'error',
			title: 'Wallet not connected',
			text: 'Please connect your wallet',
		});

		const publicSaleABI = PublicSaleABI;
		const contractPublicSale = new web3.eth.Contract(publicSaleABI, settingData.launchpadContractAddress);
		try {
			const projectOwner = await contractPublicSale.methods.project().call();
			if (fromAddress.toString().toLowerCase() !== projectOwner[10].toString().toLowerCase()) {
				Swal.fire({
					icon: 'error',
					title: 'Permission Denied',
					text: 'You do not have permission to perform this action.',
				});
				return;
			}
			let depositFee = formikReclaim.values.depositFee * 10
			let withdrawFee = formikReclaim.values.withdrawFee * 10
			let tokenClaimFee = formikReclaim.values.tokenClaimFee * 10

			if (depositFee >= 0.1 * 10 && withdrawFee >= 0.1 * 10 && tokenClaimFee >= 0.1 * 10) {
				const setFeeReceipt = await contractPublicSale.methods
					.setFees(depositFee, withdrawFee, tokenClaimFee)
					.send({ from: fromAddress });

				Swal.fire({
					icon: 'success',
					title: 'Transaction Successful',
					text: 'The fees have been set successfully.',
				});

				try {
					var formData = new FormData();
					formData.append("depositFee", depositFee);
					formData.append("withdrawFee", withdrawFee);
					formData.append("tokenClaimFee", tokenClaimFee);
					const response = await axios.patch(`${apiUrl}/projects/${settingData.id}`, formData);
					// console.log('Project updated successfully:', response.data);
					return response.data;
				} catch (error) {
					Swal.fire({
						title: 'Error!',
						text: `Failed to update the project: ${depositFee} ${withdrawFee} ${tokenClaimFee} ${error.response ? error.response.data.message : error.message}`,
						icon: 'error',
						confirmButtonText: 'Ok'
					});
					throw error;
				}


			} else {
				Swal.fire({
					icon: 'error',
					title: 'Invalid Input',
					text: 'All fee values must be greater than or equal to 0.1.',
				});
			}
		} catch (error) {
			console.error('Error during transaction:', error);
			Swal.fire({
				icon: 'error',
				title: 'Transaction Failed',
				text: error.message,
			});
		}
		formik.resetForm();
	}

	const addLiquidityService = async () => {
		await switchOrAddChain(settingData.network);
		const web3 = initializeWeb3();
		if (!web3) {
			Swal.fire('Error', 'Web3 initialization failed.', 'error');
			return;
		}

		try {
			await switchOrAddChain(settingData.network);
		} catch (error) {
			Swal.fire('Error', 'Network switch/addition failed: ' + error.message, 'error');
			return;
		}

		const erc20ABI = Erc20ABI;
		if (!erc20ABI) {
			Swal.fire('Error', 'ERC20 ABI not found.', 'error');
			return;
		}

		const erc20Contract = new web3.eth.Contract(erc20ABI, settingData.tokenAddress);
		if (!erc20Contract) {
			Swal.fire('Error', 'ERC20 contract initialization failed.', 'error');
			return;
		}

		// const fromAddress = await getPrimaryAccount(web3);
		const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
		const fromAddress = await sessionStorage.getItem('metamaskAddress', accounts[0]); 
		if (!fromAddress) {
			Swal.fire('Error', 'Please connect your wallet', 'error');
			return;
		}
		try {
			const publicSaleABI = PublicSaleABI;
			const contractPublicSale = new web3.eth.Contract(publicSaleABI, settingData.launchpadContractAddress);

			const projectOwner = await contractPublicSale.methods.project().call();
			if (fromAddress.toString().toLowerCase() !== projectOwner[10].toString().toLowerCase()) {
				Swal.fire({
					icon: 'error',
					title: 'Permission Denied',
					text: 'You do not have permission to perform this action.',
				});
				return;
			}
			
			const tokenRemaining = await contractPublicSale.methods.tokenRemainingLiquidity().call();
			let number = BigInt(tokenRemaining);

			if (number > 0) {
				Swal.fire('Error', 'Token liquidity is already present and must be zero before adding new liquidity.', 'error');
				return;
			}

			const decimals = await erc20Contract.methods.decimals().call();
			const decimalLiquidity = BigInt(settingData.liquidity) * 10n ** BigInt(decimals);

			const approvalReceipt = await erc20Contract.methods
				.approve(settingData.launchpadContractAddress, decimalLiquidity.toString())
				.send({ from: fromAddress });

			const addLiquidityReceipt = await contractPublicSale.methods
				.addLiquidity(decimalLiquidity.toString())
				.send({ from: fromAddress });

			Swal.fire('Success', 'Liquidity added successfully!', 'success');
		} catch (error) {
			console.error('Error during liquidity transaction:', error);
			Swal.fire('Transaction Error', 'Error during liquidity transaction: ' + error.message, 'error');
		}
	};

	// async function getBlockNumberByTimestamp(timestamp) {
	// 	const web3 = initializeWeb3();
	// 	if (!web3) {
	// 		Swal.fire('Error', 'Web3 initialization failed.', 'error');
	// 		return;
	// 	}

	// 	timestamp = BigInt(timestamp);
	
	// 	let startBlock = 0n; 
	// 	let endBlock = BigInt(await web3.eth.getBlockNumber()); 
	// 	let blockNumber = null;
	
	// 	while (startBlock <= endBlock) {
	// 		let middleBlock = (startBlock + endBlock) / 2n;
	// 		let middleBlockInfo = await web3.eth.getBlock(Number(middleBlock)); 
	
	// 		if (BigInt(middleBlockInfo.timestamp) < timestamp) {
	// 			startBlock = middleBlock + 1n;
	// 		} else if (BigInt(middleBlockInfo.timestamp) > timestamp) {
	// 			endBlock = middleBlock - 1n;
	// 		} else {
	// 			blockNumber = middleBlock;
	// 			break;
	// 		}
	// 	}
	
	   
	// 	if (!blockNumber) {
	// 		blockNumber = endBlock;
	// 	}
	
	// 	return blockNumber;
	// }

	// async function getBlockNumbers(startDate, endDate) {
	// 	try {
	// 		const startDateTimestamp = await getBlockNumberByTimestamp(startDate);
	// 		const endDateTimestamp = await getBlockNumberByTimestamp(endDate);
	// 		return {startDateTimestamp,endDateTimestamp}
	// 	} catch (error) {
	// 		console.error("Error fetching block numbers:", error);
	// 	}
	// }
	  
	const uploadAllocationHash = async () => {

		const web3 = initializeWeb3();
		if (!web3) {
			Swal.fire('Error', 'Web3 initialization failed.', 'error');
			return;
		}

		try {
			await switchOrAddChain(settingData.network);
		} catch (error) {
			Swal.fire('Error', 'Network switch/addition failed: ' + error.message, 'error');
			return;
		}
			const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
			const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
		if (!fromAddress) {
			Swal.fire('Error', 'Please connect your wallet', 'error');
			return;
		}

		try {

			

			const hash = await axios.get(`${apiUrl}/proofs/project/${settingData.id}`)
			setIsLoading(true)

			 if (hash.data.message){
			 	Swal.fire({
			 		title: "Error!",
			 		text: hash.data.message,
			 		icon: "error",
			 	})
			 	return
			 }

			 const allocationMap = new Map();
			 for (const entry of hash.data.mappedData) {
             const [user, allocation] = entry.split('~');
             allocationMap.set(user, Number(allocation));
        	 }

			 
			const publicSaleABI = PublicSaleABI;
			//const contractPublicSale = new web3.eth.Contract(publicSaleABI, '0xbf873427f62f2a95fdcb96c577d8db000707cb04'); // // to transfer from old smart contract to new smart contract.

			const contractPublicSale = new web3.eth.Contract(projectSale, settingData.launchpadContractAddress);

			// const userMap1 = new Map();
			// 		for (const user of allocationMap) {
			// 			let userDetails =  await contractPublicSale.methods.userDetails(user[0]).call()
			// 			let usdtAmount = Number(userDetails.depositedUsdt)
			// 			if (userMap1.has(user[0])) {
			// 				userMap1.set(user[0], userMap1.get(user[0]) + usdtAmount);
			// 			} else {
			// 				userMap1.set(user[0], usdtAmount);
			// 			}
			// 		}

			// try {

			// 	const resultMap = new Map();
			// 	for (const [user, userValue] of userMap1.entries()) {
			// 		if (allocationMap.has(user)) {
			// 			const allocationValue = allocationMap.get(user);
			// 			resultMap.set(user, allocationValue > userValue ? 0 : userValue - allocationValue);
			// 		}
			// 	}

			// 	let totalUserValue = 0;
			// 	for (const userValue of resultMap.values()) {
			// 		totalUserValue += userValue;
			// 	}

			// 	const usdtTokenCalculate = await contractPublicSale.methods.calculateUsdtPerToken(totalUserValue).call();
			// 	setTotalUsdtToken(Number(usdtTokenCalculate))
			// } catch (error) {
			// 	console.error('Error fetching events:', error);
			// 	Swal.fire('Error', 'Error fetching events!', 'error');
			// 	setIsLoading(false)
			// }

				// console.log("getTotoalUsdtToken",getTotoalUsdtToken)

				const uploadAllocation = await contractPublicSale.methods

				.addAllocationAndAirdropHash(hash.data.root, 0, 0)

					// .addAllocationAndAirdropHash(hash.data.root, 0, getTotoalUsdtToken)
					//.addAllocationAndAirdropHash('0x0000000000000000000000000000000000000000000000000000000000000001', 0, 0)
					.send({ from: fromAddress });

				Swal.fire('Success', 'Allocation hash added successfully!', 'success');
				setIsLoading(false)
		} catch (error) {
			console.error('Error setting allocation hash:', error);
			Swal.fire('Transaction Error', 'Error during allocation hash setting: ' + error.message, 'error');
			setIsLoading(false)
		}
	};

	const handleTextareaChange = (e) => {
		setAirdropAddress(e.target.value)
		if (airdropAddress) {
			const lineCount = (airdropAddress?.match(/\n/g) || []).length + 1; // Count the number of lines
			setInputs(lineCount)
		}
	};

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			walletAddress: airdropAddress && airdropAddress.walletAddress ? airdropAddress.walletAddress : "",
		},
		onSubmit: async () => {
			await switchOrAddChain(settingData.network);
			let newValue = airdropAddress?.replaceAll("\n", ",")?.replaceAll(":", "~");
			if (newValue.endsWith(",")) {
				newValue = newValue.slice(0, -1);
			}
			let resp = await axios.post(`${apiUrl}/proofs/airdrop`, {projectId: settingData.id, walletsData: newValue})
			const web3 = initializeWeb3();
			if (!web3) {
				Swal.fire('Error', 'Web3 initialization failed.', 'error');
				return;
			}
			if(settingData?.tokenAddress && resp.data){
				const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
				const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
				if (!fromAddress) {
					Swal.fire('Error', 'Wallet not connected', 'error');
					return;
				}
				try {
					const publicSaleABI = PublicSaleABI;
					const contractPublicSale = new web3.eth.Contract(publicSaleABI, settingData.launchpadContractAddress);
					const uploadAirdrop = await contractPublicSale.methods
						.addAllocationAndAirdropHash(resp.data.proof, 1,0)
						.send({ from: fromAddress });
					Swal.fire('Success', 'Airdrop hash added successfully!', 'success');
				} catch (error) {
					console.error('Error setting airdrop hash:', error);
					Swal.fire('Transaction Error', 'Error during airdrop hash setting: ' + error.message, 'error');
				}
			}
		}
	};

	const validation = useFormik(useFormikOptions);

	const couldHaveProofPermission = () => {
		if (auth.user.isSuperAdmin) return true
		const isUpdatePermission = hasPermission(
			["update:Proofs"],
			auth.user.allowedRoles
		);
		const isAddPermission = hasPermission(
			["create:Proofs"],
			auth.user.allowedRoles
		);
		const isViewPermission = hasPermission(
			["read:Proofs"],
			auth.user.allowedRoles
		);
		if (isUpdatePermission && isAddPermission && isViewPermission) return true;
		else if (isUpdatePermission && !isEmpty(details.id)) return true;
		else if (isAddPermission && isEmpty(details.id)) return true;
		else return false;
	};

    const setAddress = async (e) => {
		setNewContractAddress(e.target.value)
	 };

	 const handleFileLoaded = async(data) => {
        const parsedAddresses = [];
        const parsedBalances = [];
        // const parsedAllocations = [];

        data.forEach(row => {
			const address = row[0];
			// const allocationStr = row[1];
			const balanceStr = row[1];

			if (typeof address === 'string' && address.trim() !== '' &&
				typeof balanceStr === 'string' && balanceStr.trim() !== '' 
				// && typeof allocationStr === 'string' && allocationStr.trim() !== ''
			) {
				
				let balance;
				// let allocation;
				try {
					balance = parseFloat(balanceStr.replace(/[^0-9.-]+/g, ''));
					// allocation = parseFloat(allocationStr.replace(/[^0-9.-]+/g, ''));
					
					if (!isNaN(balance) && balance !== 0 ) {
						parsedAddresses.push(address);
						parsedBalances.push(Web3.utils.toWei(balance.toString(), 'ether')); 
						// parsedAllocations.push(Web3.utils.toWei(allocation.toString(), 'ether'));
					}
				} catch (error) {
					console.error('Error processing balance:', error);
				}
			} else {
				console.warn('Invalid row data:', row);
			}
		});
        await setAddresses(parsedAddresses);
		await setDeposits(parsedBalances);
		// await setAllocations(parsedAllocations)
    };

    const sendBalances = async () => {
        if (addresses.length === 0 ) {
            alert('No valid data to send');
            return;
        }

        if (window.ethereum) {
            try {
                // await window.ethereum.request({ method: 'eth_requestAccounts' });
                const web3 = new Web3(window.ethereum);
				const accounts = await web3.eth.getAccounts();
				const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]);
                const contract = new web3.eth.Contract(projectSale, newContractAddress);

                const batchSize = 500;

                for (let i = 0; i < addresses.length; i += batchSize) {
                    const batchAddresses = addresses.slice(i, i + batchSize);
                    const batchBalances = deposits.slice(i, i + batchSize);
                    // const batchAllocations = allocations.slice(i, i + batchSize);
						const tx = await contract.methods.updateUsdtDeposit(batchAddresses, batchBalances).send({ from: fromAddress });
                }

                alert('All transactions were successful!');
            } catch (error) {
                console.error('Transaction failed:', error);
                alert('Transaction failed, check the console for more details.');
            }
        } else {
            alert('MetaMask is not installed!');
        }
    };

	return (
		
		<div className="page-content">
			
			{!isDeployer ? "" :
			
				
				<Modal
					isOpen={
						isAdditionalSetting
					}
					toggle={
						projectSetting
					}
					size="xl"
					centered={true}
				>
					
					<ModalHeader
						toggle={
							projectSetting
						}
						tag="h4"
					>
						{settingData.projectTitle} 
					</ModalHeader>
					<ModalBody>
					
						<fieldset
							disabled={
								!couldHaveAddUpdatePermission()
							}
						>
							
							<Form
								onSubmit={(
									e
								) => {
									e.preventDefault();
									handleSubmitNetwork();
									return false;
								}}
							>
								<Row>
									<Col
										xs={
											12
										}
									>
										{/* Refund Type */}
										<Label htmlFor="refund-type" className="form-label">
											Liquidity, Allocation & Withdraw
										</Label>
										<div className="row align-items-center mb-6 mt-3">
											<div className="col-lg-4 mb-3">
												<button
													type="button"
													onClick={addLiquidityService}
													className="btn btn-sm btn-primary waves-effect waves-light w-60"
												>
													<i className="bx bx-up-arrow-alt align-middle font-size-16 me-2"></i>{" "}
													Add Liquidity {settingData.liquidity} {settingData.tokenSymbol}
												</button>
											</div>

											<div className="col-lg-4 mb-3">
												<button
													type="button"
													onClick={uploadAllocationHash}
													className="btn btn-sm btn-primary waves-effect waves-light w-50"
												>
													<i className="bx bx-up-arrow-alt align-middle font-size-16 me-2"></i>{" "}
													Upload Allocation Hash
												</button>
											</div>

											<div className="col-lg-4 mb-3">
												<Input className="mb-2"
														name="network"
														type="address"
														onChange={(e) => {
															setWithdrawAddress(e.target.value)
														}}
														value={withdrawAddress}>
												</Input>
												<button
													type="button"
													onClick={withdrewUSDT}
													className="btn btn-sm btn-primary waves-effect waves-light w-50"
												>
													<i className="bx bx-down-arrow-alt align-middle font-size-16 me-2"></i>{" "}
													Withdraw USDT
												</button>
											</div>
											<br></br>
											<div className="col-lg-4 mb-3">
											<Label htmlFor="refund-type" className="form-label">
													Redistribution Of Remaining Token
												</Label>
												<Input
													id="refund-type"
													name="refundType"
													type="select"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.refundType || ""}
													invalid={
														formik.touched.refundType && Boolean(formik.errors.refundType)
													}
												>
													<option value="" disabled>
														Select option
													</option>
													<option value={"Buyback Address"}>
														Buyback Address
													</option>
													<option value={"Reclaim Address"}>
														Reclaim Address
													</option>
												</Input>
												{formik.touched.refundType && formik.errors.refundType ? (
													<FormFeedback>{formik.errors.refundType}</FormFeedback>
												) : null}
											</div>

											
											<div className="col-lg-4 mt-3">
												<Input
													name="refundAddress"
													type="text"
													placeholder="Address"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.refundAddress || ""}
													invalid={
														formik.touched.refundAddress && Boolean(formik.errors.refundAddress)
													}
													style={{ height: "38px" }} // Adjust the height to match the button
												/>
												{formik.touched.refundAddress && formik.errors.refundAddress ? (
													<FormFeedback>{formik.errors.refundAddress}</FormFeedback>
												) : null}
											</div>


											<div className="col-lg-4 mt-3">
												<button
													type="button"
													onClick={(e) => refundOption(e)}
													className="btn btn-sm btn-primary waves-effect waves-light w-20"
													style={{ height: "38px" }}
												>
													<i className="bx bx-up-arrow-alt align-middle font-size-16 me-2"></i>{" "}
													Submit
												</button>
											</div>
										</div>

										{/* <Label htmlFor="refund-type" className="form-label">
											Excess Deposit
										</Label>
										<div className="card">
											<div className="card-body">
												<div className="row align-items-center mb-3">
												<Label htmlFor="addresses"><b>Upload CSV file </b></Label>
													<CSVReader
														onFileLoaded={handleFileLoaded}
														parserOptions={{ header: false }}
													/>
													<br></br>
													<br></br>
												<Label htmlFor="addresses">Contract Address </Label>

													<Input className="mb-2"
														name="network"
														type="address"
														onChange={setAddress}
														value={newContractAddress}>
													</Input>
												</div>
												<div className="col-lg-4 mt-3">
													<button
														type="button"
														onClick={sendExcessDeposit}
														className="btn btn-sm btn-primary waves-effect waves-light w-100"
														style={{ height: "38px" }}
													>
														<i className="bx bx-up-arrow-alt align-middle font-size-16 me-2"></i> Submit
													</button>
												</div>
											</div>
										</div> */}

									
									</Col>
								</Row>
							</Form>
						</fieldset>
						
						<Form
							disabled
							onSubmit={(e) => {
								e.preventDefault();
								sendBalances();
								return false;
							}}
						>
							<FormGroup>
								<fieldset
									disabled={
										!couldHaveProofPermission()
									}
								>
									<h5>Upload deposit details to new smart contract</h5>
									
									<div className="card">
											<div className="card-body">
												<div className="row align-items-center mb-3">
													<div className="col-lg-12 mb-3">
														
													<Label htmlFor="addresses"><b>Upload CSV file </b></Label>
													<CSVReader
														onFileLoaded={handleFileLoaded}
														parserOptions={{ header: false }}
													/>
													<br></br>
													<Label htmlFor="addresses"><b>Contract Address</b></Label>
													<Input className="mb-2"
														name="network"
														type="address"
														onChange={setAddress}
														value={newContractAddress}>
													</Input>
														<div className="text-end mt-3">
															<button type="submit" className="btn btn-success save-user">
																Submit
															</button>
														</div>
													</div>
												</div>
											</div>
											</div>
								</fieldset>
							</FormGroup>
						</Form>

						{/* <Form
							disabled
							onSubmit={(e) => {
								e.preventDefault();
								sendBalances(false);
								return false;
							}}
						>
							<FormGroup>
								<fieldset
									disabled={
										!couldHaveProofPermission()
									}
								>
									<h5>Upload allocation details to new smart contract</h5>
									
									<div className="card">
											<div className="card-body">
												<div className="row align-items-center mb-3">
													<div className="col-lg-12 mb-3">
														
													<Label htmlFor="addresses"><b>Upload CSV file </b></Label>
													<CSVReader
														onFileLoaded={handleFileLoaded}
														parserOptions={{ header: false }}
													/>
													<br></br>
													<Label htmlFor="addresses"><b>Contract Address</b></Label>
													<Input className="mb-2"
														name="network"
														type="address"
														onChange={setAddress}
														value={newContractAddress}>
													</Input>
														<div className="text-end mt-3">
															<button type="submit" className="btn btn-success save-user">
																Submit
															</button>
														</div>
													</div>
												</div>
											</div>
											</div>
								</fieldset>
							</FormGroup>
						</Form> */}

										{/* Fee Option (%) */}
										<Label htmlFor="refund-type" className="form-label">
											Fee Option (%)
										</Label>
										<div className="card">
											<div className="card-body">
												<div className="row align-items-center mb-3">
													{/* USDT Deposit Fee */}
													<div className="col-lg-4 mt-3">
														<Label className="form-label" htmlFor="depositFee">USDT Deposit Fee</Label>
														<Input
															id="depositFee"
															name="depositFee"
															type="text"
															placeholder="Enter USDT Deposit Fee"
															onChange={formikReclaim.handleChange}
															onBlur={formikReclaim.handleBlur}
															value={formikReclaim.values.depositFee || ""}
															invalid={formikReclaim.touched.depositFee && Boolean(formikReclaim.errors.depositFee)}
															style={{ height: "45px" }}
														/>
														{formikReclaim.touched.depositFee && formikReclaim.errors.depositFee && (
															<FormFeedback>{formikReclaim.errors.depositFee}</FormFeedback>
														)}
													</div>
													{/* USDT Withdrawal Fee */}
													<div className="col-lg-4 mt-3">
														<Label className="form-label" htmlFor="withdrawFee">USDT Withdrawal Fee</Label>
														<Input
															id="withdrawFee"
															name="withdrawFee"
															type="text"
															placeholder="Enter USDT Withdrawal Fee"
															onChange={formikReclaim.handleChange}
															onBlur={formikReclaim.handleBlur}
															value={formikReclaim.values.withdrawFee || ""}
															invalid={formikReclaim.touched.withdrawFee && Boolean(formikReclaim.errors.withdrawFee)}
															style={{ height: "45px" }}
														/>
														{formikReclaim.touched.withdrawFee && formikReclaim.errors.withdrawFee && (
															<FormFeedback>{formikReclaim.errors.withdrawFee}</FormFeedback>
														)}
													</div>

													{/* Token Claim Fee Field */}
													<div className="col-lg-4 mt-3">
														<Label className="form-label" htmlFor="tokenClaimFee">Token Claim Fee</Label>
														<Input
															id="tokenClaimFee"
															name="tokenClaimFee"
															type="text"
															placeholder="Enter Token Claim Fee"
															onChange={formikReclaim.handleChange}
															onBlur={formikReclaim.handleBlur}
															value={formikReclaim.values.tokenClaimFee || ""}
															invalid={formikReclaim.touched.tokenClaimFee && Boolean(formikReclaim.errors.tokenClaimFee)}
															style={{ height: "45px" }}
														/>
														{formikReclaim.touched.tokenClaimFee && formikReclaim.errors.tokenClaimFee && (
															<FormFeedback>{formikReclaim.errors.tokenClaimFee}</FormFeedback>
														)}
													</div>

													{/* Submit Button */}

												</div>
												<div className="col-lg-4 mt-3">
													<button
														type="button"
														onClick={reclaimOption}
														className="btn btn-sm btn-primary waves-effect waves-light w-100"
														style={{ height: "38px" }}
													>
														<i className="bx bx-up-arrow-alt align-middle font-size-16 me-2"></i> Submit
													</button>
												</div>
											</div>
										</div>

						{isLoading ? <div className="loading-overlay">
                    <Spinner color="primary" />
                	</div>: (<div>
						<Form
							disabled
							onSubmit={(e) => {
								e.preventDefault();
								validation.handleSubmit();
								return false;
							}}
						>
							<FormGroup>
								<fieldset
									disabled={
										!couldHaveProofPermission()
									}
								>
									<Label for="airdropAddress">Airdrop Details
									<Button color="link" onClick={toggleModal} style={{ marginLeft: 5, verticalAlign: 'super', padding: 0 }}>
										<FaInfoCircle />
									</Button>
									</Label>
									<Modal isOpen={modal} toggle={toggleModal}>
										<ModalHeader toggle={toggleModal}>Airdrop Template</ModalHeader>
										<ModalBody>
											0x690D703bf8FE6092Ad9b2f6Ff0dd717D72aA6C3e:500
											0x82bB77E410527B328fdA1d449DEE9527BCF483C4:100
											0xeED541b0C79785098E7106beeC320359AB9e1d48:61
										</ModalBody>
										<ModalFooter>
											<Button color="primary" onClick={toggleModal}>Close</Button>
										</ModalFooter>
									</Modal>
									<div className="card">
											<div className="card-body">
												<div className="row align-items-center mb-3">
													<div className="col-lg-12 mb-3">
														<span>
															Root : {airdropRoot}
														</span><br></br>
														<span>
															Total Airdrop wallets : {airdropwalletscount}
														</span><br></br>
														{formik.touched.refundType && formik.errors.refundType ? (
															<FormFeedback>{formik.errors.refundType}</FormFeedback>
														) : null}
														<br></br>
														<Input
															type="textarea"
															name="airdropAddress"
															id="airdropAddress"
															value={airdropAddress}
															onChange={handleTextareaChange}
															style={{ minHeight: "38px", maxHeight: "300px", height: inputcount * 24 }}
															onBlur={formikReclaim.handleBlur}
														/>
														<div className="text-end mt-3">
															<button type="submit" className="btn btn-success save-user">
																Submit
															</button>
														</div>
													</div>
												</div>
											</div>
											</div>
								</fieldset>
							</FormGroup>
						</Form>

						</div>)}
						
					</ModalBody>
					
				</Modal>
			}
			
              
            
		</div >
	)
}



export default ContractInteraction